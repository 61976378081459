import clsx from 'clsx';
import { Field, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { Button } from '../../../../../atoms/button';
import { CallUs } from '../../../../../atoms/call-us';
import { PBSLink } from '../../../../../atoms/link';
import { SidebarHeader } from '../../../../../atoms/sidebar-header';
import { TextInput } from '../../../../../atoms/text-input';

import {
	sleep,
	sendTrackingData,
	getCookie,
	pageUrlFomater,
} from '../../../../../../utils';
import {
	validateString,
	validateEmail,
	validatePhoneNumber,
} from '../../../../../../utils/marketo';

import { useFormValidation } from '../../../../../../hooks/form-validation';

import { useLocation } from '@reach/router';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSalesforceApiCA } from '../../../../../../hooks/salesforce-ca';
import { useSalesforceAzureApi } from '../../../../../../hooks/salesforce-azure-ca';

// Helper function to format phone number to E.164
const formatPhoneToE164 = (phoneNumber, countryCode = '1') => {
	const digits = phoneNumber.replace(/\D/g, '');
	if (phoneNumber.startsWith('+')) {
		return phoneNumber;
	}
	if (digits.startsWith(countryCode)) {
		return `+${digits}`;
	}
	return `+${countryCode}${digits}`;
};

const DownloadFormCA = ({
	setResponseSent,
	downloadId,
	gtmTag,
	ctaText,
	formType = 'whitepaper',
	title = 'Get your free download',
	subText = 'Tell us a few details and we’ll send your free download straight to your inbox',
}) => {
	const { executeRecaptcha } = useGoogleReCaptcha();
	const { fetchSalesforceCA } = useSalesforceApiCA();
	const { fetchSalesforceAzure } = useSalesforceAzureApi();
	const [submitting, setSubmitting] = useState(false);

	const initialValidations = {
		FirstName: { isValid: true },
		LastName: { isValid: true },
		Email: { isValid: true },
		Phone: { isValid: true },
		Company: { isValid: true },
	};

	// const [technicalError, setTechnicalError] = useState(false);

	const [isError, setIsError] = useState(false);
	const { validations, validateForm } = useFormValidation(initialValidations);

	// const [referralCookie, setReferralCookie] = useState('');
	// const [keywordCookie, setKeywordCookie] = useState('');
	// const [mediumCookie, setMediumCookie] = useState('');
	// const [campaignCookie, setCampaignCookie] = useState('');
	// const [msclkidCookie, setMsclkidCookie] = useState('');
	// const [msclkidLastCookie, setMsclkidLastCookie] = useState('');
	// const [gclidCookie, setGclidCookie] = useState('');
	// const [gclidLastCookie, setGclidLastCookie] = useState('');
	// const [campaignLastCookie, setCampaignLastCookie] = useState('');
	// const [contentLastCookie, setContentLastCookie] = useState('');
	// const [mediumLastCookie, setMediumLastCookie] = useState('');
	// const [sourceLastCookie, setSourceLastCookie] = useState('');
	// const [termLastCookie, setTermLastCookie] = useState('');
	// const [googleCookie, setGoogleCookie] = useState('');
	// const [socialId, setSocialId] = useState('');
	// const [formDetails, setformDetails] = useState({
	// 	id: '',
	// 	url: '',
	// 	marketoId: '',
	// });

	// useEffect(() => {
	// 	if (!locale) return;
	// 	if (formId) {
	// 		switch (locale) {
	// 			case 'en-CA':
	// 				setformDetails({
	// 					id: 4766,
	// 					url: 'https://app-ab31.marketo.com',
	// 					marketoId: '544-LUT-177',
	// 				});
	// 				break;
	// 			case 'en-IE':
	// 				setformDetails({
	// 					id: 7387,
	// 					url: 'https://app-lon04.marketo.com',
	// 					marketoId: '023-IMK-845',
	// 				});
	// 				break;
	// 			default:
	// 				setformDetails({
	// 					id: formId,
	// 					url: 'https://app-lon04.marketo.com',
	// 					marketoId: '023-IMK-845',
	// 				});
	// 		}
	// 	}
	// }, [locale, formId]);

	const submitButtonText =
		formType === 'SafeCheck'
			? ctaText || 'Get my sample report'
			: 'Get my free download';

	// useEffect(() => {
	// 	// eslint-disable-next-line compat/compat
	// 	const urlObj = new URL(pageUrl);
	// 	setSocialId(urlObj.searchParams.get('social_id'));
	// }, [pageUrl]);

	const handleClickTracking = () => {
		const gtmStartedEvent = window.dataLayer?.find(
			(element) => element['gtm.start']
		);
		if (!gtmStartedEvent) return;

		if (gtmTag === 'PPCResource') {
			sendTrackingData('ppc_download_button', {
				form_name: title,
			});
		} else {
			sendTrackingData('arrange_a_callback', {
				form_name: title,
			});
		}
	};

	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const companyRef = useRef(null);
	const emailRef = useRef(null);
	const phoneNumberRef = useRef(null);

	const initialValues = {
		FirstName: '',
		LastName: '',
		Email: '',
		Phone: '',
		Company: '',
	};

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: true,
	});

	const locationUrl = useLocation();
	const pageUrl = pageUrlFomater(locationUrl);

	const submitHandler = async (event) => {
		if (!executeRecaptcha) {
			return;
		}
		event.preventDefault();

		const { formEntries, isValid } = validateForm(event.currentTarget);

		if (!isValid) return;

		setSubmitting(true);

		// Format phone number to E.164
		const formattedPhone = formatPhoneToE164(formEntries.Phone);

		// Single dataLayer push with all necessary data
		if (window.dataLayer) {
			window.dataLayer.push({
				event: 'ec_form_submit',
				user_data: {
					email: formEntries.Email,
					phone: formattedPhone,
				},
			});
		}

		const payloadData = {
			jobTitle: formEntries.Title,
			firstName: formEntries.FirstName,
			lastName: formEntries.LastName,
			company: formEntries.Company,
			phoneNumber: formEntries.Phone,
			email: formEntries.Email,
			leadSource: 'Website',
			leadSourceDetails: 'Guide Download',
			policyNumber: downloadId, //Temporary solution to ensure the data is stored without any errors
			MSCLKID: getCookie('msclkid'),
			MSCLKID_last: getCookie('msclkid_last'),
			GCLID: getCookie('gclid'),
			GCLID_last: getCookie('gclid_last'),
			pageUrl,
			utmCampaign: getCookie('utm_campaign'),
			utmMedium: getCookie('utm_medium'),
			utmSource: getCookie('utm_source'),
			utmTerm: getCookie('utm_term'),
		};

		const response = await fetchSalesforceCA(
			executeRecaptcha,
			payloadData,
			'POST'
		);

		if (response.status !== 200) {
			localStorage.removeItem('formEmail');
			localStorage.setItem('formEmail', formEntries.email);
			await fetchSalesforceAzure(payloadData);
		}

		await sleep(500);
		setSubmitting(false);
		handleClickTracking();

		switch (response.status) {
			case 200:
				setResponseSent(true);
				// setReference(response.body.identifier);
				break;
			case 403:
				setIsError(true);
				break;
			default:
				setIsError(true);
				break;
		}
	};

	// const formik = useFormik({
	// 	initialValues: {
	// 		FirstName: '', // First Name
	// 		LastName: '', // Last Name
	// 		Email: '',
	// 		Phone: '',
	// 		Company: '',
	// 		Title: '',
	// 		PostalCode: '',
	// 		PersonSource: leadSource,
	// 		LeadSource: leadSource,
	// 		guideID: downloadId,
	// 		referalSource: referralCookie,
	// 		keyword: keywordCookie,
	// 		utm_medium: mediumCookie,
	// 		utm_campaign: campaignCookie,
	// 		MSCLKID__c: msclkidCookie,
	// 		MSCLKID_last: msclkidLastCookie,
	// 		GCLID__c: gclidCookie,
	// 		gCLID_last: gclidLastCookie,
	// 		utm_campaign_last__c: campaignLastCookie,
	// 		utm_content_last__c: contentLastCookie,
	// 		utm_medium_last__c: mediumLastCookie,
	// 		utm_source_last__c: sourceLastCookie,
	// 		utm_term_last__c: termLastCookie,
	// 		Google_Cookie_ID__c: googleCookie,
	// 		socialCampaignID: socialId,
	// 	},
	// 	enableReinitialize: true,
	// 	validateOnChange: false,
	// 	validateOnBlur: false,
	// 	onSubmit: async (data) => {
	// 		if (locale === 'en-CA') {
	// 			// eslint-disable-next-line no-param-reassign
	// 			data.LastName = `${data.FirstName} ${data.LastName}`;
	// 		}
	// 		await sleep(500);
	// 		useMarketoFormSubmit(data, formDetails.id, MktoForms2Instances)
	// 			.then(() => {
	// 				formik.setStatus('submitted');
	// 				setResponseSent(true);
	// 			})
	// 			.catch(() => {
	// 				formik.setStatus('error');
	// 				setTechnicalError(true);
	// 			})
	// 			.finally(() => {
	// 				handleClickTracking();
	// 			});
	// 	},
	// });

	// useEffect(() => {
	// 	if (Object.keys(formik.errors).length > 0) {
	// 		const inputRef = inputRefs.current[Object.keys(formik.errors)[0]];
	// 		return inputRef.focus();
	// 	}
	// }, [formik.errors, formik.isSubmitting]);

	// useEffect(() => {
	// 	const loadForm = () =>
	// 		loadScript(`${formDetails.url}/js/forms2/js/forms2.min.js`)
	// 			.then(() => {
	// 				MktoForms2Instances.get(`${formDetails.url}`).loadForm(
	// 					`${formDetails.url}`,
	// 					`${formDetails.marketoId}`,
	// 					`${formDetails.id}`
	// 				);
	// 			})
	// 			.catch(() => {});

	// 	if (window.requestIdleCallback) {
	// 		window.requestIdleCallback(loadForm);
	// 	} else {
	// 		setTimeout(loadForm);
	// 	}

	// 	setReferralCookie(getCookie('utm_source'));
	// 	setKeywordCookie(getCookie('utm_term'));
	// 	setMediumCookie(getCookie('utm_medium'));
	// 	setCampaignCookie(getCookie('utm_campaign'));
	// 	setMsclkidCookie(getCookie('msclkid'));
	// 	setMsclkidLastCookie(getCookie('msclkid_last'));
	// 	setGclidCookie(getCookie('gclid'));
	// 	setGclidLastCookie(getCookie('gclid_last'));
	// 	setCampaignLastCookie(getCookie('utm_campaign_last'));
	// 	setContentLastCookie(getCookie('utm_content_last'));
	// 	setMediumLastCookie(getCookie('utm_medium_last'));
	// 	setSourceLastCookie(getCookie('utm_source_last'));
	// 	setTermLastCookie(getCookie('utm_term_last'));
	// 	setGoogleCookie(getCookie('Google_Cookie_ID__c'));
	// }, [formDetails, MktoForms2Instances]);

	useEffect(() => {
		if (!validations.FirstName.isValid && firstNameRef.current) {
			return firstNameRef.current.focus();
		}

		if (!validations.LastName.isValid && lastNameRef.current) {
			return lastNameRef.current.focus();
		}

		if (!validations.Email.isValid && emailRef.current) {
			return emailRef.current.focus();
		}

		if (!validations.Phone.isValid && phoneNumberRef.current) {
			return phoneNumberRef.current.focus();
		}

		if (!validations.Company.isValid && companyRef.current) {
			return companyRef.current.focus();
		}
	}, [validations]);

	return isError ? (
		<>
			<SidebarHeader
				heading="We've run into a technical error with your submission"
				text="Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
				ariaLive="assertive"
			/>
			<CallUs className="p-4" />
		</>
	) : (
		<FormikProvider value={formik}>
			<form
				// data-formid={formDetails.id}
				data-forminstance="one"
				onSubmit={submitHandler}
				className="flex flex-col justify-between w-full mx-auto grow"
			>
				<SidebarHeader heading={title} text={subText} />
				<div className="flex flex-col grow">
					<fieldset
						className="flex flex-col px-4 pt-10 bg-white grow"
						disabled={submitting}
					>
						<legend className="sr-only">
							Enter your personal details
						</legend>

						<Field name="FirstName" validate={validateString}>
							{({ field }) => (
								<TextInput
									id="FirstName"
									name="FirstName"
									
									labelText="First name"
									aria-required="true"
									className="w-full wrapper-small"
									ref={firstNameRef}
									showValidation={
										!validations.FirstName.isValid &&
										!firstNameRef.current.value
									}
									validationMessage={
										!validations.FirstName.isValid
											? 'Please enter your first name'
											: null
									}
									{...field}
								/>
							)}
						</Field>
						<Field name="LastName" validate={validateString}>
							{({ field }) => (
								<TextInput
									id="LastName"
									name="LastName"
									labelText="Last name"
									aria-required="true"
									className="w-full wrapper-small"
									ref={lastNameRef}
											showValidation={
												!validations.LastName.isValid &&
												!lastNameRef.current.value
											}
											validationMessage={
												!validations.LastName.isValid
													? 'Please enter your last name'
													: null
											}
									{...field}
								/>
							)}
						</Field>

						<Field name="Company" validate={validateString}>
							{({ field }) => (
								<TextInput
									id="Company"
									name="Company"
									
									labelText="Company"
									aria-required="true"
									className="w-full wrapper-small"
									ref={companyRef}
										showValidation={
											!validations.Company.isValid &&
											!companyRef.current.value
										}
										validationMessage={
											!validations.Company.isValid
												? 'Please enter your company'
												: null
										}
									{...field}
								/>
							)}
						</Field>

						<Field name="Title" validate={validateString}>
							{({ field }) => (
								<TextInput
									id="Title"
									name="Title"
									
									labelText="Job title"
									aria-required="true"
									className="w-full wrapper-small"
									
									{...field}
								/>
							)}
						</Field>

						<hr className="mt-1 mb-10 border-blue-300" />

						<Field name="Email" validate={validateEmail}>
							{({ field }) => (
								<TextInput
									id="Email"
									type="email"
									name="Email"
									
									labelText="Email address"
									aria-required="true"
									className="w-full wrapper-small"
									ref={emailRef}
										showValidation={
											!validations.Email.isValid &&
											!emailRef.current.value
										}
										validationMessage="Please enter your email address"
									{...field}
								/>
							)}
						</Field>

						<Field name="Phone" validate={validatePhoneNumber}>
							{({ field }) => (
								<TextInput
									id="Phone"
									name="Phone"
									
									labelText="Phone number"
									aria-required="true"
									className="w-full wrapper-small"
									ref={phoneNumberRef}
										showValidation={
											!validations.Phone.isValid &&
											!phoneNumberRef.current.value
										}
										validationMessage="Please enter a phone number"
									
									{...field}
								/>
							)}
						</Field>
					</fieldset>

					<p className="px-4 text-base-f">
						View our{' '}
						<PBSLink
							to="https://www.peninsulagrouplimited.com/ca/privacy-policy/"
							variant="Link"
						>
							privacy notice
						</PBSLink>
						.
					</p>
				</div>

				<div className="px-4 py-12 bg-white">
					<div className="wrapper-small">
						<Button
							type="submit"
							className={clsx(
								formik.isSubmitting
									? 'justify-center loading'
									: null
							)}
						>
							<span
								className={clsx(
									formik.isSubmitting ? 'invisible' : null
								)}
							>
								{submitButtonText}
							</span>
							{formik.isSubmitting ? (
								<span className="sr-only" aria-live="assertive">
									Submitting your details, please wait...
								</span>
							) : null}
						</Button>
					</div>
				</div>
			</form>
		</FormikProvider>
	);
};

DownloadFormCA.defaultProps = {
	leadSource: '',
	downloadId: '',
	ctaText: '',
	gtmTag: '',
};

DownloadFormCA.propTypes = {
	setResponseSent: PropTypes.func.isRequired,
	leadSource: PropTypes.string,
	downloadId: PropTypes.string,
	formId: PropTypes.number.isRequired,
	formType: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	subText: PropTypes.string.isRequired,
	ctaText: PropTypes.string,
	gtmTag: PropTypes.string,
};

export { DownloadFormCA };
